<template>
    <seccion-datos :titulo="datos" @cambios="guardar()" :botonGuardar="permisomodif">
        <div class="row">
            <div class="col-md-12">
                <div class="form-group">
                    <label>{{ $t('general.nombre') }}</label>
                    <input :disabled="!permisomodif" type="text" v-model="nombre" class="form-control">
                </div>
            </div>

        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="form-group">
                    <label>Razón social</label>
                    <input :disabled="!permisomodif" type="text" v-model="razon_social" class="form-control">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-8">
                <div class="form-group">
                    <label>{{ $t('general.direccion') }}</label>
                    <input :disabled="!permisomodif" type="text" v-model="direccion" class="form-control">
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group">
                    <label>{{ $t('general.cpostal') }}</label>
                    <input :disabled="!permisomodif" type="text" v-model="postal_operario" class="form-control">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-5">
                <div class="form-group">
                    <label>{{ $t('general.poblacion') }}</label>
                    <input :disabled="!permisomodif" type="text" v-model="poblacion_operario" class="form-control">
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group">
                    <label>{{ $t('general.provincia') }}</label>
                    <input type="text" :disabled="!permisomodif" v-model="provincia_operario" class="form-control">
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <label>{{ $t('general.telefono') }}</label>
                    <input type="text" v-model="telefono2" :disabled="!permisomodif" class="form-control">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-2">
                <div class="form-group">
                    <label>NIF</label>
                    <select v-model="tipo_identificacion" :disabled="!permisomodif" class="form-control">
                        <option value="N.I.F.">N.I.F.</option>
                        <option value="C.I.F.">C.I.F.</option>
                        <option value="N.I.E.">N.I.E.</option>
                        <option value="OTROS">{{ $t('general.otros') }}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group">
                    <label>&nbsp;</label>
                    <input type="text" v-model="nif" :disabled="!permisomodif" class="form-control">
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label>EMail</label>
                    <input type="text" v-model="email" :disabled="!permisomodif" class="form-control">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label>{{ $t('general.movil') }}</label>
                    <input type="text" v-model="telefono1" :disabled="!permisomodif" class="form-control">
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label>{{ $t('general.fax') }}</label>
                    <input type="text" v-model="fax" :disabled="!permisomodif" class="form-control">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="form-group">
                    <label>IBAN</label>
                    <div class="input-group mb-6">
                        <input type="text" v-model="iban_1_operario" :disabled="!permisomodif"
                            class="form-control form-control-sm" style="width:20px">
                        <span class="input-group-text form-control-sm" id="basic-addon1">-</span>
                        <input type="text" v-model="iban_2_operario" :disabled="!permisomodif"
                            class="form-control form-control-sm">
                        <span class="input-group-text form-control-sm" id="basic-addon1">-</span>
                        <input type="text" v-model="iban_3_operario" :disabled="!permisomodif"
                            class="form-control form-control-sm">
                        <span class="input-group-text form-control-sm" id="basic-addon1">-</span>
                        <input type="text" v-model="iban_4_operario" :disabled="!permisomodif"
                            class="form-control form-control-sm">
                        <span class="input-group-text form-control-sm" id="basic-addon1">-</span>
                        <input type="text" v-model="iban_5_operario" :disabled="!permisomodif"
                            class="form-control form-control-sm">
                        <span class="input-group-text form-control-sm" id="basic-addon1">-</span>
                        <input type="text" v-model="iban_6_operario" :disabled="!permisomodif"
                            class="form-control form-control-sm">
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="form-group">
                    <label>CCC</label>
                    <div class="input-group mb-6">
                        <input type="text" v-model="ccc1" class="form-control form-control-sm" :disabled="!permisomodif"
                            style="width:20px">
                        <span class="input-group-text form-control-sm" id="basic-addon1">-</span>
                        <input type="text" v-model="ccc2" :disabled="!permisomodif"
                            class="form-control form-control-sm">
                        <span class="input-group-text form-control-sm" id="basic-addon1">-</span>
                        <input type="text" v-model="ccc3" :disabled="!permisomodif"
                            class="form-control form-control-sm">
                        <span class="input-group-text form-control-sm" id="basic-addon1">-</span>
                        <input type="text" v-model="ccc4" :disabled="!permisomodif"
                            class="form-control form-control-sm">
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="form-group">
                    <label>{{ $t('general.personacontacto') }}</label>
                    <input type="text" v-model="persona_contacto" :disabled="!permisomodif" class="form-control">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="form-group">
                    <label>{{ $t('general.observaciones') }}</label>
                    <textarea v-model="observaciones_operario" :disabled="!permisomodif"
                        class="form-control"></textarea>
                </div>
            </div>
        </div>
        <div v-if="acceso">
            <br>
            <h5 style="font-weight: bold;"> Datos de usuario</h5>
            <div class="row">
                <div class="col-md-7">
                    <div class="form-group">
                        <label>{{ $t('general.usuario') }}</label>
                        <input type="text" class="form-control nombre_completo dato" id="nombre_completo3"
                            v-model="login" autocomplete="off">
                    </div>

                </div>
                <div class="col-md-5">
                    <div class="form-group">
                        <label>{{ $t('general.clave') }}</label>
                        <input type="password" class="form-control nombre_completo dato" v-model="clave"
                            id="nombre_completo123" autocomplete="new-password">
                    </div>
                </div>
            </div>
        </div>
    </seccion-datos>
</template>
<script>
import { PwgsApi } from '../../../../services/PwgsApi';
export default {
    props: ['id', 'operario', 'acceso', 'permisomodifs'],
    data() {
        return {
            permisomodif:null,
            login:'',
            clave:'',
            nombre: '',
            fax:'',
            telefono1: '',
            telefono2: '',
            nif: '',
            email: '',
            direccion: '',
            postal_operario: '',
            poblacion_operario: '',
            provincia_operario: '',
            tipo_identificacion: '',
            iban_1_operario: '',
            iban_2_operario: '',
            iban_3_operario: '',
            iban_4_operario: '',
            iban_5_operario: '',
            iban_6_operario: '',
            ccc1: '',
            ccc2: '',
            ccc3: '',
            ccc4: '',
            persona_contacto: '',
            observaciones_operario: '',
            datos: "Datos",
            razon_social: '',
            
        }
    },
    methods: {
        cargarDatosOperario(num) {
            console.log('num', num, this.operario);
            this.$store.dispatch('cambiarCargando', true);
                this.nombre = this.operario.nombre;
                this.fax = this.operario.fax;
                this.telefono1 = this.operario.telefono1;
            this.telefono2 = this.operario.telefono2;
                this.nif = this.operario.nif;
                this.email = this.operario.email;
                this.direccion = this.operario.direccion;
                this.postal_operario = this.operario.postal_operario;
                this.poblacion_operario = this.operario.poblacion_operario;
                this.provincia_operario = this.operario.provincia_operario;
                this.tipo_identificacion = this.operario.tipo_identificacion,
                this.iban_1_operario = this.operario.iban_1_operario;
                this.iban_2_operario = this.operario.iban_2_operario;
                this.iban_3_operario = this.operario.iban_3_operario;
                this.iban_4_operario = this.operario.iban_4_operario;
                this.iban_5_operario = this.operario.iban_5_operario;
                this.iban_6_operario = this.operario.iban_6_operario;
                this.ccc1 = this.operario.numero_entidad;
                this.ccc2 = this.operario.numero_oficina;
                this.ccc3 = this.operario.numero_dc;
                this.ccc4 = this.operario.numero_cuenta;
                this.persona_contacto = this.operario.persona_contacto;
            this.observaciones_operario = this.operario.observaciones_operario;
            this.razon_social = this.operario.razon_social;
            this.datos = this.$t("general.datos");
           this.$store.dispatch('cambiarCargando', false);
            
        },
        async guardar() {
            /* put  modulos/pwgsapi/index.php/operarios/:id */
            const api = new PwgsApi;
            const subidadatos = { nombre: this.nombre, fax: this.fax, telefono1: this.telefono1, telefono2: this.telefono2, nif: this.nif, email: this.email, direccion: this.direccion, postal_operario: this.postal_operario, poblacion_operario: this.poblacion_operario, provincia_operario: this.provincia_operario, tipo_identificacion: this.tipo_identificacion, iban_1_operario: this.iban_1_operario, iban_2_operario: this.iban_2_operario, iban_3_operario: this.iban_3_operario, iban_4_operario: this.iban_4_operario, iban_5_operario: this.iban_5_operario, iban_6_operario: this.iban_6_operario, persona_contacto: this.persona_contacto, numero_entidad: this.ccc1, numero_oficina: this.ccc2, numero_dc: this.ccc3, numero_cuenta: this.ccc4, observaciones_operario: this.observaciones_operario, };
            if (this.id > 0) {
                try {
                    this.$store.dispatch('cambiarGuardando', true);
                    await api.put('operarios/' + this.operario.id, subidadatos);
                    this.$store.dispatch('cambiarGuardando', false);
                    this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Datos guardados correctamente', life: 2000 });
                }
                  catch (error) {
                    this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
                }
            }
            else {
                /* post  modulos/pwgsapi/index.php/operarios */
                try {
                    const resp = await api.post('operarios', subidadatos);
                    this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Operario creado correctamente', life: 2000 });
                    try{
                        await api.post('usuarios', {operarios_idoperario:resp.id, nombre: this.login, contraseña: this.clave});
                    }catch(e){
                        this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Se ha creado el operario pero no el usuario: '+e, life: 7000 });
                    }
                    //this.$router.push('/operarios/' + resp.id);
                }
                  catch (error) {
                    this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Error creando el operario: '+error, life: 5000 });
                }
            }
            
        }
    },
    mounted() {
        if(this.acceso){
            this.permisomodif = true;
        }else{
            console.log('permimo', this.permisomodifs);
            this.permisomodif = this.permisomodifs;
        }
        this.cargarDatosOperario(1);
    },
    watch: {
        operario() {
            if(this.acceso){
            this.permisomodif = true;
            }else{
                this.permisomodif = this.permisomodifs;
            }
            this.cargarDatosOperario(2);
        }
    },
}
</script>
<style>
.navbar-default .navbar-nav>.open>a,
.navbar-default .navbar-nav>.open>a:focus,
.navbar-default .navbar-nav>.open>a:hover {
    color: #555;
    background-color: #e7e7e7;
}
</style>